<template>
  <div>
    <div>
      <div class="erp-form__header header-bar__sticky">
        <div class="erp-form__title">审核{{ isCope === 1 ? '应收' : '应付' }}</div>
        <div class="erp-form-bar">
          <el-button class="erp-primary__btn" size="small" type="primary" @click="submit">提交</el-button>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <div class="order-base__warp op-16 wide-dialog-width">
        <el-row :gutter="30" type="flex" class="flex-warp">
          <el-col :span="12">
            <erp-view label="销售合同号" :value="baseData.businessContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="baseData.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="件数" :value="baseData.packageCountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="实际净重(kg)" :value="baseData.actualWeightSumDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="供应商合同号" :value="baseData.supplierContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" :value="baseData.containerNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" :value="baseData.consigneeShortName"></erp-view>
          </el-col>
        </el-row>
      </div>
      <!-- 应收项目 -->
      <div v-if="formData.arAudit && isCope === 1">
        <div style="margin-top: 30px; margin-bottom: 12px" class="order-title__bar op-16">
          <div class="erp-form__title f16">应收项目</div>
          <div class="erp-product__count">
            <span class="count-name">创建时间：</span>
            <span class="count-value" style="margin-right: 16px">{{ baseData.arAudit.createdTime }}</span>
            <span class="count-name">创建人：</span>
            <span class="count-value">{{ baseData.arAudit.creator }}</span>
          </div>
        </div>
        <div class="wide-table-header">
          <el-table :data="formData.arAudit" border class="egrid erp-table__list erp-view__table">
            <el-table-column label="费用类型" align="center" show-overflow-tooltip width="120" class-name="no-pd">
              <template #default="scope">
                <template v-if="!scope.row.isLast">
                  <div>{{ filterName(scope.row.feeTypeDesc) }}</div>
                </template>
                <template v-else>
                  <div>合计</div>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="金额(人民币)" align="center" show-overflow-tooltip width="110" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.amountRmbDesc) }}
              </template>
            </el-table-column>
            <el-table-column label="日期" align="center" show-overflow-tooltip width="110" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.arPayDate) }}
              </template>
            </el-table-column>
            <el-table-column :label="'金额(' + arTradeCurrencyDesc + ')'" align="center" show-overflow-tooltip width="110" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.arAmountTcDesc) }}
              </template>
            </el-table-column>
            <el-table-column label="汇率" align="center" show-overflow-tooltip width="80" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.arExchangeRate) }}
              </template>
            </el-table-column>
            <el-table-column :label="'结算金额(' + arTradeCurrencyDesc + ')'" align="center" show-overflow-tooltip width="126" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.arSettleAmountTcDesc) }}
              </template>
            </el-table-column>
            <el-table-column label="凭据文件" align="center" show-overflow-tooltip width="90" class-name="no-pd">
              <template #default="scope">
                <tableFileContent
                  :readOnly="true"
                  :fileUrlsArray="scope.row.fileVoList"
                  key-name="orderArapFileName"
                  key-url="orderArapFileUrl"
                  key-id="orderArapFileId"
                >
                </tableFileContent>
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center" class-name="no-pd" show-overflow-tooltip>
              <template #default="scope">
                {{ filterName(scope.row.remark) }}
              </template>
            </el-table-column>
            <el-table-column v-if="baseData.category === 1" label="是否同步到应付" align="center" width="86" class-name="no-pd" show-overflow-tooltip>
              <template #default="scope">
                {{ filterName(scope.row.isSyncToApDesc) }}
              </template>
            </el-table-column>

            <el-table-column label="审核说明" prop="contractAmountDisplay" align="center" width="180" class-name="no-pd">
              <template #default="scope">
                <template v-if="!scope.row.isLast">
                  <el-input v-model="scope.row.auditDesc" placeholder :maxlength="30"></el-input>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="contractWeightDisplay" align="center" class-name="no-pd" width="160">
              <template #default="scope">
                <template v-if="!scope.row.isLast">
                  <div>
                    <el-radio-group v-model="scope.row.auditStatus" class="erp-radio">
                      <el-radio :label="2">通过</el-radio>
                      <el-radio :label="3">不通过</el-radio>
                    </el-radio-group>
                  </div>
                </template>
                <template v-else>
                  <div class="" style="display: flex; justify-content: space-between; padding: 0 14px">
                    <div class="record__link" @click="setAuditStatus('arAudit', 2)">全通过</div>
                    <div class="record__link" @click="setAuditStatus('arAudit', 3)">全不通过</div>
                  </div>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!--应付项目  -->
      <div v-if="formData.apAudit && isCope === 2">
        <div style="margin-top: 30px; margin-bottom: 12px" class="order-title__bar op-16">
          <div class="erp-form__title f16">应付项目</div>
          <div class="erp-product__count">
            <span class="count-name">创建时间：</span>
            <span class="count-value" style="margin-right: 16px">{{ baseData.apAudit.createdTime }}</span>
            <span class="count-name">创建人：</span>
            <span class="count-value">{{ baseData.apAudit.creator }}</span>
          </div>
        </div>
        <div>
          <el-table :data="formData.apAudit" border class="egrid erp-table__list erp-view__table">
            <el-table-column label="费用类型" align="center" width="190" class-name="no-pd" show-overflow-tooltip>
              <template #default="scope">
                <template v-if="!scope.row.isLast">
                  <div>{{ filterName(scope.row.feeTypeDesc) }}</div>
                </template>
                <template v-else>
                  <div>合计</div>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="金额(人民币)" align="center" width="100" class-name="no-pd" show-overflow-tooltip>
              <template #default="scope">
                {{ filterName(scope.row.amountRmbDesc) }}
              </template>
            </el-table-column>
            <el-table-column label="日期" align="center" show-overflow-tooltip width="90" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.arPayDate) }}
              </template>
            </el-table-column>

            <el-table-column label="提货款" align="center" width="120" class-name="no-pd" show-overflow-tooltip>
              <template #default="scope">
                {{ filterName(scope.row.apPickupAmountDesc) }}
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center" class-name="no-pd" show-overflow-tooltip width="320">
              <template #default="scope">
                {{ filterName(scope.row.remark) }}
              </template>
            </el-table-column>
            <el-table-column label="凭据文件" align="center" width="80" class-name="no-pd" show-overflow-tooltip>
              <template #default="scope">
                <template v-if="!scope.row.isLast">
                  <tableFileContent
                    :readOnly="true"
                    :fileUrlsArray="scope.row.fileVoList"
                    key-name="orderArapFileName"
                    key-url="orderArapFileUrl"
                    key-id="orderArapFileId"
                  >
                  </tableFileContent>
                </template>
                <template v-else></template>
              </template>
            </el-table-column>

            <el-table-column label="审核说明" prop="contractAmountDisplay" align="center" class-name="no-pd">
              <template #default="scope">
                <template v-if="!scope.row.isLast">
                  <el-input v-model="scope.row.auditDesc" placeholder :maxlength="30"></el-input>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="contractWeightDisplay" align="center" width="160" class-name="no-pd">
              <template #default="scope">
                <template v-if="!scope.row.isLast">
                  <div>
                    <el-radio-group v-model="scope.row.auditStatus" class="erp-radio">
                      <el-radio :label="2">通过</el-radio>
                      <el-radio :label="3">不通过</el-radio>
                    </el-radio-group>
                  </div>
                </template>
                <template v-else>
                  <div class="" style="display: flex; justify-content: space-between; padding: 0 14px">
                    <div class="record__link" @click="setAuditStatus('apAudit', 2)">全通过</div>
                    <div class="record__link" @click="setAuditStatus('apAudit', 3)">全不通过</div>
                  </div>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="formData.modifyAudit && isCope === 1">
        <div style="margin-top: 30px; margin-bottom: 12px" class="order-title__bar op-16">
          <div class="erp-form__title f16">申请修改应收</div>
          <div class="erp-product__count">
            <span class="count-name">创建时间：</span>
            <span class="count-value" style="margin-right: 16px">{{ baseData.modifyAudit.createdTime }}</span>
            <span class="count-name">创建人：</span>
            <span class="count-value">{{ baseData.modifyAudit.creator }}</span>
          </div>
        </div>
        <div class="wide-table-header">
          <el-table :data="formData.modifyAudit" border class="egrid erp-table__list erp-view__table">
            <el-table-column label="费用类型" align="center" show-overflow-tooltip width="120" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.feeTypeDesc) }}
              </template>
            </el-table-column>
            <el-table-column label="金额(人民币)" align="center" show-overflow-tooltip width="140" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.amountRmb) }}
              </template>
            </el-table-column>
            <el-table-column label="日期" align="center" show-overflow-tooltip width="90" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.arPayDate) }}
              </template>
            </el-table-column>
            <el-table-column :label="'金额(' + arTradeCurrencyDesc + ')'" align="center" show-overflow-tooltip width="100" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.arAmountTcDesc) }}
              </template>
            </el-table-column>
            <el-table-column label="汇率" align="center" show-overflow-tooltip width="70" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.arExchangeRate) }}
              </template>
            </el-table-column>

            <el-table-column label="修改金额" align="center" show-overflow-tooltip width="120" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.amountRmbAfterModifyDesc) }}
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center" show-overflow-tooltip width="186" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.remark) }}
              </template>
            </el-table-column>
            <el-table-column label="凭据文件" align="center" show-overflow-tooltip width="130" class-name="no-pd">
              <template #default="scope">
                <tableFileContent
                  :readOnly="true"
                  :fileUrlsArray="scope.row.fileVoList"
                  key-name="orderArapFileName"
                  key-url="orderArapFileUrl"
                  key-id="orderArapFileId"
                >
                </tableFileContent>
              </template>
            </el-table-column>

            <el-table-column label="审核说明" prop="contractAmountDisplay" align="center" class-name="no-pd">
              <template #default="scope">
                <el-input v-model="scope.row.auditDesc" placeholder :maxlength="30"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="contractWeightDisplay" align="center" width="160" class-name="no-pd">
              <template #default="scope">
                <div>
                  <el-radio-group v-model="scope.row.auditStatus" class="erp-radio">
                    <el-radio :label="2">通过</el-radio>
                    <el-radio :label="3">不通过</el-radio>
                  </el-radio-group>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="formData.modifyApAudit && isCope == 2">
        <div style="margin-top: 30px; margin-bottom: 12px" class="order-title__bar op-16">
          <div class="erp-form__title f16">申请修改应付</div>
          <div class="erp-product__count">
            <span class="count-name">创建时间：</span>
            <span class="count-value" style="margin-right: 16px">{{ baseData.modifyApAudit.createdTime }}</span>
            <span class="count-name">创建人：</span>
            <span class="count-value">{{ baseData.modifyApAudit.creator }}</span>
          </div>
        </div>
        <div>
          <el-table :data="formData.modifyApAudit" border class="egrid erp-table__list erp-view__table">
            <el-table-column label="费用类型" align="center" show-overflow-tooltip width="120" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.feeTypeDesc) }}
              </template>
            </el-table-column>
            <el-table-column label="金额(人民币)" align="center" show-overflow-tooltip width="140" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.amountRmb) }}
              </template>
            </el-table-column>
            <el-table-column label="日期" align="center" show-overflow-tooltip width="90" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.arPayDate) }}
              </template>
            </el-table-column>
            <el-table-column :label="'金额(' + arTradeCurrencyDesc + ')'" align="center" show-overflow-tooltip width="100" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.arAmountTcDesc) }}
              </template>
            </el-table-column>
            <el-table-column label="汇率" align="center" show-overflow-tooltip width="70" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.arExchangeRate) }}
              </template>
            </el-table-column>

            <el-table-column label="修改金额" align="center" show-overflow-tooltip width="120" class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.amountRmbAfterModifyDesc) }}
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center" show-overflow-tooltip class-name="no-pd">
              <template #default="scope">
                {{ filterName(scope.row.remark) }}
              </template>
            </el-table-column>
            <el-table-column label="凭据文件" align="center" show-overflow-tooltip width="130" class-name="no-pd">
              <template #default="scope">
                <tableFileContent
                  :readOnly="true"
                  :fileUrlsArray="scope.row.fileVoList"
                  key-name="orderArapFileName"
                  key-url="orderArapFileUrl"
                  key-id="orderArapFileId"
                >
                </tableFileContent>
              </template>
            </el-table-column>

            <el-table-column label="审核说明" prop="contractAmountDisplay" align="center" width="180" class-name="no-pd">
              <template #default="scope">
                <el-input v-model="scope.row.auditDesc" placeholder :maxlength="30"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="contractWeightDisplay" align="center" width="160" class-name="no-pd">
              <template #default="scope">
                <div>
                  <el-radio-group v-model="scope.row.auditStatus" class="erp-radio">
                    <el-radio :label="2">通过</el-radio>
                    <el-radio :label="3">不通过</el-radio>
                  </el-radio-group>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
export default {
  components: {
    tableFileContent,
  },
  props: {
    type: { type: [String] },
    baseData: { type: [Object] }, // 基础信息
    arTradeCurrencyDesc: { type: [String, Number] },
    orderArapId: { type: [String, Number] },
    version: { type: [String, Number] },
    isCope: { type: [String, Number] },
    data: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      formData: {
        apAudit: null,
        arAudit: null,
        modifyAudit: null,
        orderArapId: this.orderArapId,
        version: this.version,
        modifyApAudit: null,
        isAjaxLoading: false,
      },
    };
  },
  created() {
    const { apAudit, arAudit, modifyAudit, modifyApAudit } = JSON.parse(JSON.stringify(this.baseData));
    console.log('🚀 ~ file: auditAccrued.vue ~ line 416 ~ created ~ this.baseData', this.baseData);
    if (apAudit) {
      this.formData.apAudit = this.setAudit(apAudit.listVo);
    }
    if (arAudit) {
      this.formData.arAudit = this.setAudit(arAudit.listVo);
    }
    if (modifyAudit) {
      this.formData.modifyAudit = modifyAudit.listVo;
    }
    if (modifyApAudit) {
      this.formData.modifyApAudit = modifyApAudit.listVo;
    }
  },
  methods: {
    setAudit(data) {
      // 设置自定义列
      if (data && data.length === 1) {
        return data;
      } else {
        let amountRmbDesc = data.reduce((total, item) => {
          const price = Number(item.amountRmb);
          return total + price;
        }, 0);
        amountRmbDesc = this._formatFloat(amountRmbDesc, 2);
        const lastData = {
          isLast: true,
          amountRmbDesc: this._thousandBitSeparator(amountRmbDesc),
        };
        data.push(lastData);
        return data;
      }
    },
    setAuditStatus(type, value) {
      // 审核全通过
      this.formData[type].forEach((v, i) => {
        if (i < this.formData[type].length - 1) {
          this.formData[type][i].auditStatus = value;
        }
      });
    },
    validateData() {
      const { apAudit, arAudit, modifyAudit, modifyApAudit } = this.formData;
      let status = true;
      if (apAudit && this.isCope === 2) {
        for (let i = 0; i < apAudit.length; i++) {
          const item = apAudit[i];
          if (item.auditStatus !== 2 && item.auditStatus !== 3 && !item.isLast) {
            this.errorTip('请进行审核操作');
            status = false;
            break;
          }
          if (item.auditStatus === 3 && !item.auditDesc) {
            this.errorTip('审核不通过请说明原因');
            status = false;
            break;
          }
        }
      }
      if (arAudit && this.isCope === 1) {
        for (let i = 0; i < arAudit.length; i++) {
          const item = arAudit[i];
          if (item.auditStatus !== 2 && item.auditStatus !== 3 && !item.isLast) {
            this.errorTip('请进行审核操作');
            status = false;
            break;
          }
          if (item.auditStatus === 3 && !item.auditDesc) {
            this.errorTip('审核不通过请说明原因');
            status = false;
            break;
          }
        }
      }
      if (modifyAudit && this.isCope === 1) {
        for (let i = 0; i < modifyAudit.length; i++) {
          const item = modifyAudit[i];
          if (item.auditStatus !== 2 && item.auditStatus !== 3) {
            this.errorTip('请进行审核操作');
            status = false;
            break;
          }
          if (item.auditStatus === 3 && !item.auditDesc) {
            this.errorTip('审核不通过请说明原因');
            status = false;
            break;
          }
        }
      }

      if (modifyApAudit && this.isCope === 2) {
        for (let i = 0; i < modifyApAudit.length; i++) {
          const item = modifyApAudit[i];
          if (item.auditStatus !== 2 && item.auditStatus !== 3) {
            this.errorTip('请进行审核操作');
            status = false;
            break;
          }
          if (item.auditStatus === 3 && !item.auditDesc) {
            this.errorTip('审核不通过请说明原因');
            status = false;
            break;
          }
        }
      }

      return status;
    },
    submit() {
      const status = this.validateData();
      if (status) {
        const params = JSON.parse(JSON.stringify(this.formData));
        if (this.isCope === 1) {
          params.apAudit = null;
          params.modifyApAudit = null;
          params.arAudit?.length > 1 && params.arAudit.pop();
        } else {
          params.arAudit = null;
          params.modifyAudit = null;
          params.apAudit?.length > 1 && params.apAudit.pop();
        }
        if (!this.isAjaxLoading) {
          this.isAjaxLoading = true;
          this.ajax({
            type: 'POST',
            url: '/malicrm/orderArap/auditArap',
            data: params,
            success: (res) => {
              if (res.code === 200) {
                setTimeout(() => {
                  this.isAjaxLoading = false;
                }, 1000);
                this.$emit('cancel', true);
              } else {
                this.isAjaxLoading = false;

                this.errorTip(res.message);
              }
            },
          });
        }
      }
    },
    handlerSummary(array, data, attribute, index) {
      let total = data.reduce((v) => {
        const price = Number(attribute);
        return v + price;
      });
      total = this._formatFloat(total, 2);
      array[index] = this._thousandBitSeparator(total);
    },
    // summaryFn() {
    //   const summaryColumns = ['合计', '-', '', '', '', '', '', '', '', '', '', ''];
    //   const data = this.formData.arAudit;
    //   // 金额人民币(合计)
    //   this.handlerSummary(summaryColumns, data, data.amountRmb, 3);
    //   // 金额美元（合计）
    //   this.handlerSummary(summaryColumns, data, data.arAmountTc, 4);
    //   // 结算金额美元（合计）
    //   this.handlerSummary(summaryColumns, data, data.arSettleAmountTc, 6);
    //   return summaryColumns;
    // },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
.wide-dialog-width {
  width: 1000px;
  margin-left: 10px;
}
</style>
